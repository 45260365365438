<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t("friend_suggestion.head") }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t("friend_suggestion.head_list") }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input ref="startDate" :date="dateFrom" :label="$t('friend_suggestion.date_from')"
                @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input ref="endDate" :date="dateTo" :label="$t('friend_suggestion.date_to')"
                @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" @click="searchRef" class=""> {{ $t('search') }} </v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <v-spacer />
          <span class="px-3 mt-5"> {{ $t('search') }}:</span>
          <div style="width: 150px" class="mt-5">
            <v-text-field v-debounce:500ms="searchKeyword" :date="searchData" outlined dense hide-details />
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :no-data-text="$t('not_found')"
            :no-results-text="$t('not_found')" :options.sync="pagination" :server-items-length="pagination.totalItems"
            :items-per-page="pagination.rowsPerPage" :loading="loading" :page.sync="pagination.currentPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :items="members">
            <!-- <template v-slot:[`item.bank`]="{ item }">
              <img alt="Avatar" width="25px" :src="require(`@/assets/images/logos/banks/${item.bank}.png`)" />
            </template>
            <template v-slot:[`item.commission`]="{ item }">
              <v-btn color="success" class="white--text text-capitalize"> Commission </v-btn>
            </template>
            <template v-slot:[`item.members`]="{ item }">
              <v-btn color="primary" class="white--text text-capitalize"> 0 คน : ข้อมูลรายงาน </v-btn>
            </template>
            <template v-slot:[`item.summary`]="{ item }">
              <v-btn color="warning" class="white--text text-capitalize"> ข้อมูลรายงาน </v-btn>
            </template>
            <template v-slot:[`item.summary`]="{ item }">
              <v-btn color="warning" class="white--text text-capitalize"> ข้อมูลรายงาน </v-btn>
            </template> -->
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      searchData: null,
      headers: [
        {
          text: this.$t('friend_suggestion.recommender'),
          value: 'recommender',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion.register'),
          value: 'applicant',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('friend_suggestion.date'),
          value: 'date',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      members: [],
      loading: false,
      dummy: true,
      pagination: {
        currentPage: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    // await this.getUsersAffiliate(1, 25)
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        // await this.getUsersAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && !this.dummy) await this.getUsersAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
        if (cont && this.dummy) await this.getUsersAffiliate(newValue.page, newValue.itemsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            name: 'แนะนำเพื่อน',
            url: window.location.href,
            detail: 'รายการแนะนำเพื่อน',
            admin: userSystem.name ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchRef() {
      this.dummy = false
      await this.getUsersAffiliate(1, this.pagination.rowsPerPage, this.$refs.startDate.value, this.$refs.endDate.value, this.searchData)
      this.pagination.currentPage = 1
    },
    async getUsersAffiliate(page, row, time_from, time_to, search) {
      this.loading = true
      let rs = await this.$store.dispatch('getMemberAffiliate', {
        page: page,
        rows: row,
        ...(!search ? { page: page } : {}),
        ...(!search ? { rows: row } : {}),
        ...(time_from ? { time_from: this.formatDateYYYY(time_from) } : {}),
        ...(time_to ? { time_to: this.formatDateYYYY(time_to) } : {}),
        ...(search ? { search: search } : {}),
      })
      this.pagination.totalItems = rs.count
      this.members = []
      rs.data.forEach(element => {
        this.members.push({
          id: element.id,
          applicant: element.member ? element.member.phone : null,
          recommender: element.ref_member ? element.ref_member.phone : null,
          date: element.created_at ? moment(element.created_at).format('YY-MM-DD HH:mm') : '',
        })
      })
      this.loading = false
    },
    showMemberDetail(value) {
      this.currentMemberDetail = value
      this.showDetail = true
    },
    async searchKeyword(value) {
      this.dummy = true
      this.searchData = value
      setTimeout(async () => {
        await this.getUsersAffiliate(1, this.pagination.itemsPerPage, null, null, value)
      }, 3000);
      this.loading = false
      this.pagination.currentPage = 1
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
  },
}
</script>
